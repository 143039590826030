<template>
  <div class="page-wrapper">
    <v-card class="content-card">
      <v-card-title class="card-title">
        <h2 v-t="'Printfiles.Printfiles'"></h2>
        <v-spacer></v-spacer>
        <v-row class="form-row">
          <v-text-field :min="1" type="number" v-model="nbShown" class="form-field" label="Files per page" outlined></v-text-field>
          <v-text-field v-model="search" class="search-field" label="Search" append-outer-icon="mdi-magnify" @click:append-outer="searchRef" clearable outlined></v-text-field>
        </v-row>
      </v-card-title>
      <div class="total-files">{{ $t('Orders.TotalFiles') }} {{ printfiles.total }}</div>
      <div class="pagination-container">
        <div class="pagination">
          <v-pagination v-model="currentPage" total-visible="7" :length="pages"></v-pagination>
        </div>
      </div>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class='table-header' v-t="'Orders.Name'"></th>
              <th class='table-header' v-t="'Orders.FirstDownloadDatePrint'"></th>
              <th class='table-header' v-t="'Orders.DownloadPrintFile'"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='file in shownPrintfilesFiles' :key='file.id' :id="file.id" :ref="file.id">
              <td>{{ file.printFileName }}</td>
              <td>{{ file.printFileDownloadDate }}</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="black" icon @click="downloadPrintFile(file)">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Download print file</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-pagination v-model="currentPage" total-visible="7" :length="pages"></v-pagination>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'Printfiles',
  data () {
    return {
      currentPage: 1,
      nbShown: 20,
      loading: false,
      search: '',
      total: 0,
      documents: [],
      printfiles: []
    }
  },
  async created () {
    await this.getPrintfiles()
    this.documents = this.printfiles.documents
    this.total = this.printfiles.total
  },
  computed: {
    shownPrintfilesFiles () {
      if (!this.documents) {
        return []
      }
      return this.documents.slice((this.currentPage - 1) * this.nbShown, this.currentPage * this.nbShown)
    },
    pages () {
      if (!this.documents) {
        return 0
      }
      return Math.ceil(this.documents.length / this.nbShown)
    }
  },
  watch: {
    search: function (val) {
      this.searchRef()
    },
    nbShown: function (val) {
      this.nbShown = val <= 0 ? 1 : val
      this.currentPage = 1
    }
  },
  methods: {
    async downloadPrintFile (file) {
      try {
        await this.$store.dispatch('downloadPrintFileView', file)
      } catch (err) {
        const errorString = await err.response.data.text()
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + JSON.parse(errorString))
        })
      }
    },
    searchRef () {
      if (!this.search) {
        this.documents = this.printfiles.documents
        this.total = this.documents.length
        return
      }
      this.currentPage = 1
      const searchText = this.search.trim().toLowerCase()
      this.documents = this.printfiles.documents.filter((document) => {
        return document.printFileName.toLowerCase() === searchText
      })
      this.total = this.documents.length
    },
    async getPrintfiles () {
      await this.$store.dispatch('getAllPrintfiles')
      this.printfiles = this.$store.getters['GET_ALL_PRINTFILES']
      this.documents = this.printfiles.documents
    }
  }
}
</script>

<style scoped>
.page-wrapper {
  padding: 5px;
}

.content-card {
  margin: auto;
  padding: 3px;
}

.card-title {
  display: flex;
  align-items: baseline;
}

.form-row {
  display: flex;
  justify-content: flex-end;
}

.form-field {
  width: 10%;
  margin-left: 40%;
}

.search-field {
  margin-right: 5%;
  margin-left: 1%;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-files {
  text-align: left;
  min-width: fit-content;
}

.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 10px;
  text-align: center !important;
}

.table-header {
  width: 20%;
}

.v-pagination {
  margin: 0 auto;
}
</style>
